import React from "react";
import Button from "./Button";
import Stop from "../assets/stop.png";
import Point from "../assets/point.png";

const Requirements = () => {
  return (
    <div className="relative overflow-hidden bg-cover bg-center">
      <div className="py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8 lg:py-20 p-5">
        <div className="max-w-2xl mx-auto sm:max-w-xl md:max-w-5xl">
          <div className="text-center">
            <div className="max-w-2xl mb-10 md:mx-auto sm:text-center lg:max-w-5xl md:mb-12">
              <div className="flex items-center justify-center gap-5 w-28 mx-auto mb-5">
                <img src={Stop} alt="" />
                <img src={Point} alt="" />
              </div>
              <h2 className="hidden md:flex">We'll outperform your best results or don't pay us!</h2>
              <h2 className="flex md:hidden">We'll beat your best results or don't pay us!</h2>
              <div className="letter">
                <p>Dear business builder,</p>
                <p>
                  If you want to{" "}
                  <span className="font-bold">
                    create a torrential downpour of buyers that flood your
                    business…
                  </span>
                </p>
                <div className="flex mb-10 items-center">
                  <div className="mr-7 md:mr-20">
                    <h2 className="text-main inter font-bold">*</h2>
                  </div>
                  <div className="">
                    <p>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      In, excepturi atque porro nihil aliquam cumque odio modi
                      corporis expedita animi voluptas quidem soluta sit nemo ex
                      eius minima iusto ipsum?
                    </p>
                  </div>
                </div>
                <div className="flex mb-10 items-center">
                  <div className="mr-7 md:mr-20">
                    <h2 className="text-main inter font-bold">*</h2>
                  </div>
                  <div className="">
                    <p>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                      In, excepturi atque porro nihil aliquam cumque odio modi
                      corporis expedita animi voluptas quidem soluta sit nemo ex
                      eius minima iusto ipsum?
                    </p>
                  </div>
                </div>
              </div>
              <div className="z-10 w-full">
                <Button/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Requirements;
