import React from "react";
import Button from "./Button";
import Bracket from "../assets/bracket.svg";
import Meeting from "../assets/meeting.svg";

const Letter = () => {
  return (
    <div
      className="relative overflow-hidden bg-cover bg-center"
      id="next-section"
    >
      <div className="p-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8 lg:py-8">
        <div className="max-w-2xl mx-auto sm:max-w-xl md:max-w-5xl">
          <div className="text-center">
            <div className="max-w-2xl mb-10 md:mx-auto sm:text-center lg:max-w-5xl md:mb-12">
              <div className="letter">
                <p>Let me guess,</p>
                <p>
                  You’re a busy real estate agent, juggling showings, client
                  calls, negotiations, trying to keep everything running
                  smoothly.
                </p>
                <p>
                  But there’s one part of your business that’s always lurking in
                  the background, stressing you out.
                </p>
                <p>Yep.</p>
                <p>
                  <span className="font-semibold">Social media</span>.
                </p>
                <p>You know you’re supposed to be active on it.</p>
                <p>Everyone says it’s key to growing your business. </p>
                <p>
                  <span className="highlight">
                    More leads, more listings, more sales,
                  </span>{" "}
                  right?
                </p>
                <p>
                  You wonder where the new clients are and why your inbox isn’t
                  filling up with potential buyers or sellers.
                </p>
                <p>
                  But every time you think about it, you’re just…{" "}
                  <span className="font-semibold">tired</span>.{" "}
                </p>
                <p>
                  I mean, who has the time to constantly come up with posts,
                  interact with followers, and hope that leads{" "}
                  <span className="italic">*magically*</span> appear in your
                  inbox?
                </p>
                <p>Let me be real with you—it’s exhausting.</p>
                <p>
                  You sit there after a long day, knowing you should post
                  something, or anything...
                </p>
                <p>
                  Maybe you throw up a photo of your latest listing or a quick
                  “market update,” and then?{" "}
                </p>
                <p>
                  <span className="font-semibold">A nothing burger.</span>
                </p>
                <p>No results...</p>
                <p>
                  <span className="highlight">
                    A couple of likes, maybe a comment or two, but nothing that
                    makes you feel like your effort is worth it.
                  </span>
                </p>
                <p>Sound familiar?</p>
                <p>It’s frustrating, right?</p>
                <p>
                  Here’s the thing,{" "}
                  <span className="highlight">
                    it’s not about how much you post
                  </span>
                  —it’s about using the right strategies.
                </p>
                <div className="z-10 w-full pb-24">
                  <Button />
                </div>
                <div className="relative pt-20">
                  <img
                    src={Bracket}
                    className="absolute w-52 -top-10 md:-top-5 right-10 md:-right-16"
                    alt=""
                  />
                  <h2>What you've been doing wrong!</h2>
                </div>
                <div>
                  <img src={Meeting} className="" alt="" />
                </div>
                <p>
                  Here’s the thing no one tells you, social media is
                  overwhelming.
                </p>
                <p>
                  Between juggling your real estate business and trying to keep
                  up online, it feels like a full-time job in itself.
                </p>
                <p>
                  And yet,{" "}
                  <span className="font-semibold highlight">
                    it’s non-negotiable in today’s world.
                  </span>
                </p>
                <p>
                  If you’re not consistently showing up online, you’re missing
                  out on potential clients.
                </p>
                <p>
                  <span className="highlight">
                    People are scrolling through Instagram, Facebook, and
                    LinkedIn <span className="font-semibold">right now</span>,
                    looking for agents like you.
                  </span>
                </p>
                <p>
                  But if you’re not visible,{" "}
                  <span className="font-semibold">you’re invisible.</span>
                </p>
                <p>The problem?</p>
                <p>You can’t be everywhere, all the time.</p>
                <p>
                  <span className="highlight">
                    You shouldn’t have to spend your evenings and weekends
                    scrambling to come up with content that feels forced or
                    falls flat.
                  </span>
                </p>
                <p>The good news?</p>
                <p> You don’t have to...</p>
              </div>
              <div className="z-10 w-full">
                <Button />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Letter;
