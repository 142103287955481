import { useState, useEffect } from "react";
import Flag from "react-world-flags"; // Import the flag component
import { Link } from "react-router-dom";

const Button = ({
  cta = "CLAIM FREE 20-MIN STRATEGY SESSION",
  color = "#0086FF",
  subcolor = "00000050",
  sub = "No obligations. No BS. No hard sell.",
}) => {
  const [countryCode, setCountryCode] = useState("US"); // Default to US
  const currentDate = new Date();
  const month = currentDate.toLocaleString("default", { month: "long" });
  const day = currentDate.getDate();

  // Function to get the user's country code
  useEffect(() => {
    const getUserCountryCode = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/");
        if (!response.ok) {
          throw new Error("Failed to fetch country code");
        }
        const data = await response.json();
        setCountryCode(data.country_code || "US"); // Fallback to US if no country code
      } catch (error) {
        console.error("Error fetching country code:", error);
        setCountryCode("US"); // Fallback to US if fetch fails
      }
    };

    getUserCountryCode();
  }, []);

  return (
    <div className="flex flex-col items-center">
      <Link to="/strategy-session" className="w-full">
        <button className="funnelCTA" style={{ backgroundColor: color }}>
          <div className="slant cta">{cta}</div>
          <p
            className="!font-bold text-sm mx-auto w-full slant mt-1"
            style={{ color: `#${subcolor}` }}
          >
            {sub}
          </p>
        </button>
      </Link>

      {/* Text below the button */}
      <div className="flex items-center mt-4 gap-4 spots" style={{ color }}>
        <div className="relative w-3 h-3 flex items-center justify-center">
          <div className="absolute w-3 h-3 rounded-full bg-red-600"></div>
          <div className="absolute w-3 h-3 rounded-full bg-red-600 animate-ping"></div>
        </div>
        <div className="flex items-center text-sm md:text-md">
          <p className="font-medium text-black">
            FEW SPOTS LEFT AS OF{" "}
            <span className="font-bold">
              {month.toUpperCase()} {day}
            </span>
          </p>
        </div>
        <div>
          <Flag code={countryCode} width="24" className="rounded-sm" />
        </div>
      </div>
    </div>
  );
};

export default Button;
