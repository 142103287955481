import React, { useState } from "react";
import Button from "./Button";
import Speech from "../assets/speech.png";
import { IoChevronDown } from "react-icons/io5";
import { IoChevronUp } from "react-icons/io5";

const faqData = [
  {
    question: "Can you guarantee results?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque iste saepe, delectus earum dolore quibusdam? Eveniet modi mollitia ea exercitationem unde quidem accusamus?",
  },
  {
    question: "What services do you offer?",
    answer:
      "We offer a variety of services including real estate consulting, social media marketing, and strategy calls to help your business grow.",
  },
  {
    question: "How can I get started?",
    answer:
      "You can get started by booking a free 30-minute strategy call with us, where we'll discuss your needs and how we can help you.",
  },
];

const Questions = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleQuestion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="relative overflow-hidden bg-cover bg-center">
      <div className="py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8 lg:py-20 px-5">
        <div className="max-w-2xl mx-auto sm:max-w-xl md:max-w-5xl">
          <div className="text-center">
            <div className="max-w-2xl mb-10 md:mx-auto sm:text-center lg:max-w-5xl md:mb-12">
              <div className="flex items-center justify-center gap-5 w-28 mx-auto mb-5">
                <img src={Speech} alt="Speech bubble icon" />
              </div>
              <h2>YOUR QUESTIONS, ANSWERED!</h2>

              {/* FAQ Section */}
              <div className="faq-container space-y-3 mt-10">
                {faqData.map((faq, index) => (
                  <div
                    key={index}
                    className="shadow-sm rounded-2xl px-5 md:px-10 py-5 bg-white cursor-pointer"
                    onClick={() => toggleQuestion(index)}
                  >
                    <div className="flex justify-between items-center ">
                      <p className="font-semibold text-xl text-left md:text-4xl opacity-90">
                        {faq.question}
                      </p>
                      <span className="!text-3xl">
                        {activeIndex === index ? (
                          <IoChevronDown />
                        ) : (
                          <IoChevronUp />
                        )}
                      </span>
                    </div>
                    {activeIndex === index && (
                      <div className="mt-5 text-xl md:text-2xl text-left opacity-70">
                        <p>{faq.answer}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              {/* CTA Button */}
              <div className="z-10 w-full mt-10">
                <Button />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questions;
