import React, { useState, useEffect } from "react";
import Confetti from "react-confetti";
import Gift from "../assets/gift.png";
import GiftActive from "../assets/gift-active.png";
import Drag from "../assets/drag.svg";
import Logo from "../assets/primary.svg";
import Sparkle from "../assets/sparkles_2728.png";
import Flag from "react-world-flags"; // Import the flag component
import { FaChevronRight, FaChevronLeft } from "react-icons/fa6";
import Platforms from "./Platforms";
import { IoCheckmarkCircle } from "react-icons/io5";
import Intro from "./Intro";
import Wait from "../components/Wait";

const StrategyForm = () => {
  const [countryCode, setCountryCode] = useState("US"); // Default to US
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: "",
    budget: 0,
    current: 0,
    aim: 0,
    painPoint: "",
    startTime: "",
    goal: "",
    phoneNumber: 0,
  });
  const [confetti, setConfetti] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSliderDragged, setIsSliderDragged] = useState(false);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);

  const currentDate = new Date();
  const month = currentDate.toLocaleString("default", { month: "long" });
  const day = currentDate.getDate();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hasModalBeenShown, setHasModalBeenShown] = useState(false);

  useEffect(() => {
    const handleMouseLeave = (event) => {
      // Only trigger the modal if it hasn't been shown yet
      if (!hasModalBeenShown && event.clientY <= 0) {
        setIsModalVisible(true);
        setHasModalBeenShown(true); // Ensure the modal is not triggered again
      }
    };

    document.addEventListener("mouseleave", handleMouseLeave);

    return () => {
      document.removeEventListener("mouseleave", handleMouseLeave);
    };
  }, [hasModalBeenShown]);

  const closeModal = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    const getUserCountryCode = async () => {
      const response = await fetch("https://ipapi.co/json/");
      const data = await response.json();
      setCountryCode(data.country_code);
    };

    getUserCountryCode();
  }, []);

  useEffect(() => {
    validateForm();
  }, [formData, step, isSliderDragged]);

  useEffect(() => {
    if (confetti) {
      const timer = setTimeout(() => {
        setConfetti(false);
      }, 15000); // 15 seconds

      return () => clearTimeout(timer);
    }
  }, [confetti]);

  useEffect(() => {
    validateForm();
  }, [formData, step, selectedPlatforms]);

  const validateForm = () => {
    switch (step) {
      case 1:
        setIsFormValid(true);
        break;
      case 2:
        setIsFormValid(formData.firstName.trim() !== "");
        break;
      case 3:
        setIsFormValid(formData.budget >= 500);
        break;
      case 4:
        setIsFormValid(selectedPlatforms.length > 0);
        break;
      case 5:
        setIsFormValid(formData.painPoint.trim() !== "");
        break;
      case 6:
        setIsFormValid(formData.current >= 500);
        break;
      case 7:
        setIsFormValid(formData.aim >= 500);
        break;
      case 8:
        setIsFormValid(formData.startTime.trim() !== "");
        break;
      case 9:
        setIsFormValid(formData.firstName.trim() !== "");
        break;
      case 10:
        setIsFormValid(formData.firstName.trim() !== "");
        break;
      default:
        setIsFormValid(true);
    }
  };

  const nextStep = () => {
    if (step < 10) {
      setStep(step + 1);
    } else {
      handleSubmit();
    }

    if (step == 9) {
      setConfetti(true);
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    console.log("Form submitted with:", formData);

    //redirect to calendly page
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return <Intro />;
      case 2:
        return (
          <>
            <h1 className="w-5/12 mx-auto">
              Alright, we'll keep this short and sweet. What is your name?
            </h1>
            <input
              type="text"
              name="firstName"
              placeholder="Enter your first name"
              value={formData.firstName}
              onChange={handleChange}
              className="form-input capitalize"
            />
          </>
        );
      case 3:
        return (
          <>
            <h2 className="w-6/12 mx-auto">
              Perfect! What is your monthly marketing budget?
            </h2>
            <p className="text-xl mt-5 font-normal text-gray-500">
              This helps us qualify and identify what the best growth strategies
              are within your budget.
            </p>
            <div className="w-3/12 mx-auto">
              <div className="w-[250px] h-16 flex items-center justify-center rounded-full bg-[#fff] mx-auto mt-10 border-2">
                <div className="flex justify-center text-3xl font-normal">
                  <span>
                    $
                    {formData.budget >= 100000
                      ? "100,000+"
                      : formData.budget.toLocaleString()}
                  </span>
                </div>
              </div>
              <div className="relative">
                <input
                  type="range"
                  name="budget"
                  min="500"
                  max="100000"
                  step="500"
                  value={formData.budget}
                  onChange={handleChange}
                  className="w-full slider-input"
                />

                <img
                  src={Drag}
                  className="w-32 absolute -left-36 top-2"
                  alt=""
                />
              </div>
            </div>
          </>
        );
      case 4:
        return <Platforms setSelectedPlatforms={setSelectedPlatforms} />;
      case 5:
        return (
          <>
            <h1 className="w-6/12 mx-auto">
              Be honest,{" "}
              <span className="capitalize">{formData.firstName}</span>. What's
              the BIGGEST obstacle you're facing regarding your marketing
              efforts?
            </h1>
            <p className="text-xl mt-5 font-normal text-gray-500 w-6/12 mx-auto">
              Dig deep – is it the quality of the leads, the volume, or
              something else? We're serious about helping you so don't skip the
              sore details!
            </p>
            <textarea
              name="painPoint"
              placeholder="Briefly describe your pain points..."
              value={formData.painPoint}
              onChange={handleChange}
              className="form-input h-52"
            ></textarea>
          </>
        );
      case 6:
        return (
          <>
            <h2 className="w-6/12 mx-auto">
              What is your current MONTHLY revenue?
            </h2>
            <p className="text-xl mt-5 font-normal text-gray-500">
              This helps us qualify you and identify what the best growth
              strategies are within your budget.
            </p>
            <div className="w-3/12 mx-auto">
              <div className="w-[250px] h-16 flex items-center justify-center rounded-full bg-[#fff] mx-auto mt-10 border-2">
                <div className="flex justify-center text-3xl font-normal">
                  <span>
                    $
                    {formData.current >= 100000
                      ? "100,000+"
                      : formData.current.toLocaleString()}
                  </span>
                </div>
              </div>
              <div className="relative">
                <input
                  type="range"
                  name="current"
                  min="500"
                  max="100000"
                  step="500"
                  value={formData.current}
                  onChange={handleChange}
                  className="w-full slider-input"
                />

                <img
                  src={Drag}
                  className="w-32 absolute -left-36 top-2"
                  alt=""
                />
              </div>
            </div>
          </>
        );
      case 7:
        return (
          <>
            <h2 className="mx-auto">What is your TARGET monthly revenue?</h2>
            <div className="w-3/12 mx-auto">
              <div className="w-[250px] h-16 flex items-center justify-center rounded-full bg-[#fff] mx-auto mt-10 border-2">
                <div className="flex justify-center text-3xl font-normal">
                  <span>
                    $
                    {formData.aim >= 100000
                      ? "100,000+"
                      : formData.aim.toLocaleString()}
                  </span>
                </div>
              </div>
              <div className="relative">
                <input
                  type="range"
                  name="aim"
                  min="500"
                  max="100000"
                  step="500"
                  value={formData.aim}
                  onChange={handleChange}
                  className="w-full slider-input"
                />
                {/* Conditionally render the drag icon based on whether the slider has been dragged */}

                <img
                  src={Drag}
                  className="w-32 absolute -left-36 top-2"
                  alt=""
                />
              </div>
            </div>
          </>
        );
      case 8:
        return (
          <>
            <h2 className="w-6/12 mx-auto">
              Almost there! How soon are you looking to get started?
            </h2>
            <div className="flex justify-center flex-wrap gap-2 w-5/12 mx-auto mt-10">
              {[
                "Immediately",
                "Within 2 weeks",
                "2-4 weeks",
                "4-6 weeks",
                "6 weeks+",
                "I'm just a dreamer",
              ].map((label, index) => (
                <label
                  key={index}
                  className={`flex items-center px-3 w-[350px] gap-3 h-[50px] rounded-full cursor-pointer text-lg relative ${
                    formData.startTime === label
                      ? "bg-white border-main border-2 text-main"
                      : "bg-white border-2"
                  }`}
                >
                  <IoCheckmarkCircle
                    className={`text-3xl left-2 rounded-full bg-[#F4F4F8] ${
                      formData.startTime === label
                        ? "text-main"
                        : "text-gray-300"
                    }`}
                  />
                  <input
                    type="radio"
                    name="startTime"
                    value={label}
                    checked={formData.startTime === label}
                    onChange={handleChange}
                    onClick={nextStep}
                    className="hidden"
                  />
                  {label}
                </label>
              ))}
            </div>
          </>
        );
      case 9:
        return (
          <>
            <h2 className="w-5/12 mx-auto">
              {formData.firstName}, If you qualify for a call, do you promise
              that you will show up at your selected time? 🧐
            </h2>
            <div className="flex justify-center gap-2 w-2/12 mx-auto mt-10">
              {["No", "Yes"].map((label, index) => (
                <label
                  key={index}
                  className={`flex flex-col justify-center items-center px-3 w-[350px] gap-3 h-32 rounded-full cursor-pointer text-lg relative ${
                    formData.startTime === label
                      ? "bg-white border-main border-2 text-main"
                      : "bg-white border-2"
                  }`}
                >
                  <input
                    type="radio"
                    name="startTime"
                    value={label}
                    checked={formData.startTime === label}
                    onChange={handleChange}
                    onClick={nextStep}
                    className="hidden"
                  />
                  {label}
                </label>
              ))}
            </div>
          </>
        );
      case 10:
        return (
          <>
            <h1 className="w-6/12 mx-auto">
              BOOYAH! 🥳 We're confident we can help you achieve MAJOR growth
              with our proven strategies. Just enter your details below!
            </h1>
            <div className="mx-auto flex flex-col">
              <input
                type="text"
                name="firstName"
                placeholder="Enter your first name"
                value={formData.firstName}
                onChange={handleChange}
                className="form-input"
              />
              <input
                type="text"
                name="firstName"
                placeholder="Enter your first name"
                value={formData.firstName}
                onChange={handleChange}
                className="form-input !mt-2"
              />
              <input
                type="text"
                name="firstName"
                placeholder="Enter your first name"
                value={formData.firstName}
                onChange={handleChange}
                className="form-input !mt-2"
              />
              <input
                type="text"
                name="firstName"
                placeholder="Enter your first name"
                value={formData.firstName}
                onChange={handleChange}
                className="form-input !mt-2"
              />
              <input
                type="text"
                name="firstName"
                placeholder="Enter your first name"
                value={formData.firstName}
                onChange={handleChange}
                className="form-input !mt-2"
              />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const progressPercentage = ((step - 1) / 9) * 100;

  return (
    <section>
      {confetti && (
        <Confetti
          gravity={0.3}
          numberOfPieces={800}
          origin={{ x: 0.5, y: 1 }}
          recycle={false}
        />
      )}

      <nav className="w-full h-16 shadow-sm !bg-white flex items-center justify-center gap-5">
        <img src={Logo} width={140} alt="Company Logo" />
      </nav>

      <div className="w-4/12 mt-20 mx-auto relative flex items-center">
        <div className="flex items-center justify-between w-full relative">
          {Array.from({ length: 10 }).map((_, index) => (
            <div
              key={index}
              className={`rounded-full h-5 w-5 ${
                step >= index + 1 ? "bg-main" : "bg-gray-200"
              }`}
            >
              {index === 4 && step === 10 && (
                <img
                  src={GiftActive}
                  alt="Active Gift"
                  className="absolute w-12 -right-6 -top-3 rotate"
                />
              )}
              {index === 4 && step < 10 && (
                <img
                  src={Gift}
                  alt="Gift"
                  className="absolute w-12 -right-6 -top-3 rotate"
                />
              )}
            </div>
          ))}
        </div>
        <div
          className="h-1 bg-main rounded-full absolute -z-10"
          style={{ width: `${progressPercentage}%` }}
        ></div>
        <div className="w-full h-1 bg-gray-200 rounded-full absolute -z-20"></div>
      </div>

      <div className="mt-20 text-center form-question">
        {renderStepContent()}
      </div>

      <div className="flex w-3/12 mx-auto flex-col gap-5 items-center justify-center mt-5">
        <div className="flex w-full gap-2">
          <div className="flex flex-col w-full gap-4">
            <button
              className={`w-full text-2xl font-semibold rounded-xl p-5 ${
                isFormValid ? "bg-main text-white" : "bg-gray-300 text-gray-500"
              }`}
              onClick={nextStep}
              disabled={!isFormValid}
            >
              {step === 10 ? (
                "Submit"
              ) : (
                <span className="flex items-center gap-2 justify-center">
                  Continue
                  <FaChevronRight className="text-2xl" />
                </span>
              )}
            </button>
            <div className="flex flex-col gap-2 items-center justify-center  text-gray-600">
              <div className="flex items-center gap-4">
                <div className="relative w-3 h-3 flex items-center justify-center">
                  <div className="absolute w-3 h-3 rounded-full bg-red-600"></div>
                  <div className="absolute w-3 h-3 rounded-full bg-red-600 animate-ping"></div>
                </div>
                <div className="flex items-center">
                  <p className="font-medium">
                    Few spots left as of{" "}
                    <span className="font-bold">
                      {month.toUpperCase()} {day}
                    </span>
                  </p>
                </div>
                <Flag code={countryCode} width="30" className="rounded-sm" />
              </div>
            </div>
          </div>
          <button
            className={`text-2xl font-semibold text-gray-400 p-2 absolute top-3 left-32 ${
              step === 1 ? "hidden" : ""
            }`}
            onClick={prevStep}
          >
            <FaChevronLeft className="text-2xl" />
          </button>
        </div>
      </div>
      <div className="funnel">
        <Wait isVisible={isModalVisible} onClose={closeModal} />
      </div>
    </section>
  );
};

export default StrategyForm;
