import React, { useState } from "react";
import SEO from "../assets/seo.svg";
import Mouth from "../assets/mouth_1f444.png";
import Google from "../assets/google ads.svg";
import Facebook from "../assets/facebook.svg";
import { ImNewspaper } from "react-icons/im";
import { BsFillBellFill } from "react-icons/bs";
import { IoCheckmarkCircle } from "react-icons/io5";

const Platforms = ({ setSelectedPlatforms }) => {
  const [selectedPlatforms, setSelected] = useState([]);

  const platforms = [
    {
      id: "facebook",
      label: "Facebook Ads",
      icon: <img src={Facebook} className="h-10" alt="Facebook Ads" />,
    },
    { id: "print", label: "Print", icon: <ImNewspaper className="h-10" /> },
    {
      id: "word-of-mouth",
      label: "Word of Mouth",
      icon: <img src={Mouth} className="h-10" alt="Word of Mouth" />,
    },
    {
      id: "google",
      label: "Google Ads",
      icon: <img src={Google} className="h-10" alt="Google Ads" />,
    },
    {
      id: "seo",
      label: "SEO",
      icon: <img src={SEO} className="h-10" alt="SEO" />,
    },
    { id: "other", label: "Other", icon: <BsFillBellFill className="h-10 text-yellow-500" /> },
  ];

  const togglePlatform = (id) => {
    let updatedSelection;
    if (selectedPlatforms.includes(id)) {
      updatedSelection = selectedPlatforms.filter(
        (platform) => platform !== id
      );
    } else {
      updatedSelection = [...selectedPlatforms, id];
    }
    setSelected(updatedSelection);
    setSelectedPlatforms(updatedSelection);
  };

  const isSelected = (id) => selectedPlatforms.includes(id);

  return (
    <div>
      <h2 className="w-6/12 mx-auto">
        Perfect! How are you currently generating leads?
      </h2>
      <p className="text-xl mt-5 font-normal text-gray-500">
        (Please select all the methods you currently use.)
      </p>
      <div className="flex flex-col">
        <div className="w-5/12 mx-auto grid grid-cols-3 gap-2 mt-10">
          {platforms.map((platform) => (
            <div
              key={platform.id}
              className={`form-select ${
                isSelected(platform.id) ? "border-main bg-blue-100" : ""
              }`}
              onClick={() => togglePlatform(platform.id)}
            >
              {isSelected(platform.id) && (
                <IoCheckmarkCircle className="text-3xl absolute text-main -left-3 -top-3 border-2 border-[#F4F4F8] rounded-full bg-[#F4F4F8]" />
              )}
              {platform.icon}
              <p>{platform.label}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Platforms;
