import React from "react";
import Button from "./Button";
import Leaf from "../assets/leaf.svg";
import Stats from "../assets/stats.png";
import Statista from "../assets/statista logo.png";
import Title from "../assets/title.svg";

const Guarantee = () => {
  return (
    <div className="relative overflow-hidden bg-cover bg-center">
      <div className="py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-lg md:px-24 lg:px-8 lg:py-20 px-5">
        <div className="max-w-2xl mx-auto sm:max-w-xl md:max-w-5xl">
          <div className="text-center">
            <div className="max-w-2xl my-10 md:mx-auto sm:text-center lg:max-w-5xl md:mb-12">
              <div className="flex items-center justify-center relative mx-auto ">
                <img
                  src={Leaf}
                  className="absolute h-28 -left-3 md:h-[280px] top-0 md:-left-10 md:flex"
                  alt=""
                />
                <img
                  src={Leaf}
                  className="hidden absolute scale-x-[-1] h-28 -right-3 md:h-[280px] top-0 md:-right-10 md:flex"
                  alt=""
                />
                <h2 className="text-xl">Predictable, qualified leads</h2>
              </div>

              <div className="letter">
                <p>
                  You need more than just “likes” and “shares” to grow your
                  business.
                </p>
                <p>
                  <span className="highlight">
                    You need a system that builds and converts your social media
                    audience into real, qualified leads.
                  </span>
                </p>
                <p>But here’s the thing...</p>
                <p>
                  Most business owners don’t have the time or resources to
                  figure out a strategy for them.
                </p>
                <p>
                  <span className="highlight">
                    You’re busy running a business, and that’s where your energy
                    should be.
                  </span>
                </p>
                <p>
                  Here’s a fact to consider: global digital ad spend is
                  projected to hit $870 billion, that's right,
                  <span className="font-semibold">
                    {" "}
                    billion (with a 'B')
                  </span>{" "}
                  by 2027.
                </p>
              </div>
              <img
                src={Title}
                className="w-full md:w-[70%] mx-auto mb-5"
                alt=""
              />
              <div>
                <div className="overflow-hidden shadow rounded-xl mx-auto md:w-[80%]">
                  <img src={Stats} className="" alt="" />
                </div>
                <div className="flex items-center text-sm mx-auto md:w-[80%]  mt-2 justify-end gap-2">
                  <p className="text-gray-400 ">Source:</p>
                  <a
                    href="https://www.statista.com/statistics/237974/online-advertising-spending-worldwide/#:~:text=It%20was%20calculated%20that%20the,would%20reach%20870.85%20billion%20dollars."
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Statista} width={72} alt="" />
                  </a>
                </div>
              </div>
              <div className="letter">
                <p>
                  That means if you’re not taking the necessary actions now,{" "}
                  <span className="highlight">
                    you’ll be going out of business as competition heats up,{" "}
                    <span className="font-semibold uppercase">inevitably</span>.
                  </span>
                </p>
                <p>
                  What if, instead of stressing over social media, it worked for
                  you?
                </p>
                <p>
                  What if, while you’re out closing deals, your leads were
                  rolling in—<span className="font-semibold">predictably?</span>
                </p>
                <p>Guess what?</p>
                <p>it’s possible.</p>
                <p>
                  <span className="highlight">
                    Our battle-tested system, coupled with award-winning
                    designers and our <span className="italic">lethal</span>{" "}
                    strategies does just that.
                  </span>
                </p>
                <p>You deserve to focus on what you’re best at.</p>
                <p>Let us take care of the rest.</p>
              </div>
              <div className="z-10 w-full">
                <Button color="#0086FF" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guarantee;
